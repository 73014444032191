<script>
import {getImage} from "@/services/helpers";

export default {
  name: "InputSelectBtn",
  props: {
    img: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      required: true,
    },
    text: {
      type: String,
      required: true,
    },
    link: {
      type: String,
      required: true,
    },
  },
  methods: {
    getImage
  },
}
</script>

<template>
  <div class="wrapper">
    <v-img
      v-if="img"
      :src="getImage(img)"
      contain
      class="mb-4"
    />
    <v-btn
      outlined
      block
      x-large
      depressed
      :href="link"
      class="button"
      color="#d2d2d7"
    >
      {{ text || name }}
    </v-btn>
  </div>
</template>

<style scoped lang="scss">
.wrapper {
  width: 100%;
}
.selected {
  border-color: var(--v-primary-base);
  border-width: 2px;
  color: black;
}
.button::v-deep {
  border-radius: 10px;
  height: 60px !important;
  .v-btn__content {
    color: black;
    width: 100%;
    white-space: pre-line;
    word-break: break-word;
  }
}
</style>
